.side-nav-container {
  transition: width 0.5s;
}

.side-nav-header-text {
  color: orange;
  text-shadow: #9f9f9f 1px 1px 1px;
}

.side-nav-item-text {
  color: #9f9f9f;
}

.controlBtn {
  transition: margin-left 0.5s;
  top: 72px;
}

.side-nav-container.collapsed nav ul {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s;
}

.side-nav-container.collapsed img {
  opacity: 0;
  transition: opacity 0.5s;
}

.side-nav-container nav ul {
  transition: opacity 0.5s;
}

.side-nav-container.collapsed {
  width: 0px;
}

.side-nav-container {
  width: 288px;
}
